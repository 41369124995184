import { default as index7keTxj1PUVMeta } from "/usr/src/app/pages/[country]/about-us/index.vue?macro=true";
import { default as bagksyWaGh5ihMeta } from "/usr/src/app/pages/[country]/bag.vue?macro=true";
import { default as indexuNAF7NpO3EMeta } from "/usr/src/app/pages/[country]/become-a-dealer/index.vue?macro=true";
import { default as index3LAXG2RaWaMeta } from "/usr/src/app/pages/[country]/become-a-distributor/index.vue?macro=true";
import { default as index8RBFfDxPwyMeta } from "/usr/src/app/pages/[country]/checkout/[code]/index.vue?macro=true";
import { default as resultf6q8dHVSWIMeta } from "/usr/src/app/pages/[country]/checkout/[code]/result.vue?macro=true";
import { default as the_45museumoweu3zHn92Meta } from "/usr/src/app/pages/[country]/company/the-museum.vue?macro=true";
import { default as contact_45usN7kU8b0uxJMeta } from "/usr/src/app/pages/[country]/contact-us.vue?macro=true";
import { default as cookie_45policyYZnv67bBKLMeta } from "/usr/src/app/pages/[country]/cookie-policy.vue?macro=true";
import { default as disclaimerjmlf4zLUbYMeta } from "/usr/src/app/pages/[country]/disclaimer.vue?macro=true";
import { default as how_45to_45buyLyZI3d1c4cMeta } from "/usr/src/app/pages/[country]/how-to-buy.vue?macro=true";
import { default as indexiC2XA9kvitMeta } from "/usr/src/app/pages/[country]/index.vue?macro=true";
import { default as login31SGcCIZ7BMeta } from "/usr/src/app/pages/[country]/login.vue?macro=true";
import { default as indexQuPdRHyIDYMeta } from "/usr/src/app/pages/[country]/manuals/index.vue?macro=true";
import { default as index1eQN3vFTuyMeta } from "/usr/src/app/pages/[country]/my-account/addresses/index.vue?macro=true";
import { default as indexjOLwuCpDGTMeta } from "/usr/src/app/pages/[country]/my-account/bikes/index.vue?macro=true";
import { default as indexC7On3iwKIYMeta } from "/usr/src/app/pages/[country]/my-account/index.vue?macro=true";
import { default as indexyod0bKbstNMeta } from "/usr/src/app/pages/[country]/my-account/orders/[code]/index.vue?macro=true";
import { default as indexovZMFqsrpwMeta } from "/usr/src/app/pages/[country]/my-account/orders/index.vue?macro=true";
import { default as _91slug_id_93rTeuqZVpP4Meta } from "/usr/src/app/pages/[country]/news/[slug_id].vue?macro=true";
import { default as indexx5KTTyMZ6bMeta } from "/usr/src/app/pages/[country]/news/index.vue?macro=true";
import { default as _91slug_93cJ0aCvWaW8Meta } from "/usr/src/app/pages/[country]/news/update/[slug].vue?macro=true";
import { default as privacy_45policyk53vLSd6nLMeta } from "/usr/src/app/pages/[country]/privacy-policy.vue?macro=true";
import { default as _91slug_930u3Pwi1NwQMeta } from "/usr/src/app/pages/[country]/products/[slug].vue?macro=true";
import { default as recoveryPThe6UFtN7Meta } from "/usr/src/app/pages/[country]/recovery.vue?macro=true";
import { default as registersNXM6chP2sMeta } from "/usr/src/app/pages/[country]/register.vue?macro=true";
import { default as _91type_93w52rTHu4oXMeta } from "/usr/src/app/pages/[country]/search/[type].vue?macro=true";
import { default as shipping_45policyBqwhYCQbEnMeta } from "/usr/src/app/pages/[country]/shipping-policy.vue?macro=true";
import { default as stores2ptQ6k0P4fMeta } from "/usr/src/app/pages/[country]/stores.vue?macro=true";
import { default as subscriber_45verify0PthfNDVK8Meta } from "/usr/src/app/pages/[country]/subscriber-verify.vue?macro=true";
import { default as termsk13osguBD1Meta } from "/usr/src/app/pages/[country]/terms.vue?macro=true";
import { default as test_45drive6jsIU5PiWIMeta } from "/usr/src/app/pages/[country]/test-drive.vue?macro=true";
import { default as unsubscribetDbuCSAmhAMeta } from "/usr/src/app/pages/[country]/unsubscribe.vue?macro=true";
import { default as _91token_93btSrYlR05NMeta } from "/usr/src/app/pages/[country]/update-password/[token].vue?macro=true";
import { default as warrantyvi90ACRfzWMeta } from "/usr/src/app/pages/[country]/warranty.vue?macro=true";
import { default as indexn1wUZoXaX0Meta } from "/usr/src/app/pages/[country]/work-with-us/index.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
export default [
  {
    name: "country-about-us",
    path: "/:country()/about-us",
    component: () => import("/usr/src/app/pages/[country]/about-us/index.vue")
  },
  {
    name: "country-bag",
    path: "/:country()/bag",
    component: () => import("/usr/src/app/pages/[country]/bag.vue")
  },
  {
    name: "country-become-a-dealer",
    path: "/:country()/become-a-dealer",
    component: () => import("/usr/src/app/pages/[country]/become-a-dealer/index.vue")
  },
  {
    name: "country-become-a-distributor",
    path: "/:country()/become-a-distributor",
    component: () => import("/usr/src/app/pages/[country]/become-a-distributor/index.vue")
  },
  {
    name: "country-checkout-code",
    path: "/:country()/checkout/:code()",
    meta: index8RBFfDxPwyMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/index.vue")
  },
  {
    name: "country-checkout-code-result",
    path: "/:country()/checkout/:code()/result",
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/result.vue")
  },
  {
    name: "country-company-the-museum",
    path: "/:country()/company/the-museum",
    component: () => import("/usr/src/app/pages/[country]/company/the-museum.vue")
  },
  {
    name: "country-contact-us",
    path: "/:country()/contact-us",
    component: () => import("/usr/src/app/pages/[country]/contact-us.vue")
  },
  {
    name: "country-cookie-policy",
    path: "/:country()/cookie-policy",
    component: () => import("/usr/src/app/pages/[country]/cookie-policy.vue")
  },
  {
    name: "country-disclaimer",
    path: "/:country()/disclaimer",
    component: () => import("/usr/src/app/pages/[country]/disclaimer.vue")
  },
  {
    name: "country-how-to-buy",
    path: "/:country()/how-to-buy",
    component: () => import("/usr/src/app/pages/[country]/how-to-buy.vue")
  },
  {
    name: "country",
    path: "/:country()",
    component: () => import("/usr/src/app/pages/[country]/index.vue")
  },
  {
    name: "country-login",
    path: "/:country()/login",
    meta: login31SGcCIZ7BMeta || {},
    component: () => import("/usr/src/app/pages/[country]/login.vue")
  },
  {
    name: "country-manuals",
    path: "/:country()/manuals",
    component: () => import("/usr/src/app/pages/[country]/manuals/index.vue")
  },
  {
    name: "country-my-account-addresses",
    path: "/:country()/my-account/addresses",
    meta: index1eQN3vFTuyMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/addresses/index.vue")
  },
  {
    name: "country-my-account-bikes",
    path: "/:country()/my-account/bikes",
    meta: indexjOLwuCpDGTMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/index.vue")
  },
  {
    name: "country-my-account",
    path: "/:country()/my-account",
    meta: indexC7On3iwKIYMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/index.vue")
  },
  {
    name: "country-my-account-orders-code",
    path: "/:country()/my-account/orders/:code()",
    meta: indexyod0bKbstNMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/orders/[code]/index.vue")
  },
  {
    name: "country-my-account-orders",
    path: "/:country()/my-account/orders",
    meta: indexovZMFqsrpwMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/orders/index.vue")
  },
  {
    name: "country-news-slug_id",
    path: "/:country()/news/:slug_id()",
    component: () => import("/usr/src/app/pages/[country]/news/[slug_id].vue")
  },
  {
    name: "country-news",
    path: "/:country()/news",
    component: () => import("/usr/src/app/pages/[country]/news/index.vue")
  },
  {
    name: "country-news-update-slug",
    path: "/:country()/news/update/:slug()",
    component: () => import("/usr/src/app/pages/[country]/news/update/[slug].vue")
  },
  {
    name: "country-privacy-policy",
    path: "/:country()/privacy-policy",
    component: () => import("/usr/src/app/pages/[country]/privacy-policy.vue")
  },
  {
    name: "country-products-slug",
    path: "/:country()/products/:slug()",
    component: () => import("/usr/src/app/pages/[country]/products/[slug].vue")
  },
  {
    name: "country-recovery",
    path: "/:country()/recovery",
    component: () => import("/usr/src/app/pages/[country]/recovery.vue")
  },
  {
    name: "country-register",
    path: "/:country()/register",
    meta: registersNXM6chP2sMeta || {},
    component: () => import("/usr/src/app/pages/[country]/register.vue")
  },
  {
    name: "country-search-type",
    path: "/:country()/search/:type()",
    component: () => import("/usr/src/app/pages/[country]/search/[type].vue")
  },
  {
    name: "country-shipping-policy",
    path: "/:country()/shipping-policy",
    component: () => import("/usr/src/app/pages/[country]/shipping-policy.vue")
  },
  {
    name: "country-stores",
    path: "/:country()/stores",
    component: () => import("/usr/src/app/pages/[country]/stores.vue")
  },
  {
    name: "country-subscriber-verify",
    path: "/:country()/subscriber-verify",
    component: () => import("/usr/src/app/pages/[country]/subscriber-verify.vue")
  },
  {
    name: "country-terms",
    path: "/:country()/terms",
    component: () => import("/usr/src/app/pages/[country]/terms.vue")
  },
  {
    name: "country-test-drive",
    path: "/:country()/test-drive",
    component: () => import("/usr/src/app/pages/[country]/test-drive.vue")
  },
  {
    name: "country-unsubscribe",
    path: "/:country()/unsubscribe",
    component: () => import("/usr/src/app/pages/[country]/unsubscribe.vue")
  },
  {
    name: "country-update-password-token",
    path: "/:country()/update-password/:token()",
    meta: _91token_93btSrYlR05NMeta || {},
    component: () => import("/usr/src/app/pages/[country]/update-password/[token].vue")
  },
  {
    name: "country-warranty",
    path: "/:country()/warranty",
    component: () => import("/usr/src/app/pages/[country]/warranty.vue")
  },
  {
    name: "country-work-with-us",
    path: "/:country()/work-with-us",
    component: () => import("/usr/src/app/pages/[country]/work-with-us/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue")
  }
]